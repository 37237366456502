export const userFetched = (username, password) => ({
    type: 'FETCH_USER_SUCCESS',
    username: username,
    password: password
});

export const userValid = (valid) => ({
    type: 'CONNECTED_USER_SUCCESS',
    valid: valid
});

export const saveQueue = (queue) => ({
    type: 'SAVE_QUEUE_SUCCESS',
    queue: queue
});