/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router as _Router, Route, Switch, Redirect} from "react-router-dom";


// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "react-notifications/dist/react-notifications.css"
import {connect, Provider} from "react-redux";
import {store} from "./store";
import {Connector, subscribe} from "mqtt-react";
import GridContainer from "./components/Grid/GridContainer";
import GridItem from "./components/Grid/GridItem";
import Card from "./components/Card/Card";
import CardHeader from "./components/Card/CardHeader";
// import CardIcon from "./components/Card/CardIcon";
import CardBody from "./components/Card/CardBody";
import CustomInput from "./components/CustomInput/CustomInput";
import Button from "./components/CustomButtons/Button";
import CardFooter from "./components/Card/CardFooter";
import preval from "preval.macro";
// import fs from "fs";
import {userFetched, saveQueue, userValid} from "./actions";
import {withStyles} from "@material-ui/styles";
import styles from "./assets/jss/material-dashboard-react/views/dashboardStyle";
import _Sensors from "./components/Sensors/Sensors";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import LoggedWatcher from "./components/LoggedWatcher/LoggedWatcher";
import {BookmarkRounded} from "@material-ui/icons";
// import _Sensors from "./components/Sensors/Sensors";


import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';


const isSafari = navigator.userAgent.indexOf("Safari");


const parse = function (message) {
    try {
        var item = JSON.parse(message);
        return item;
    } catch (e) {
        return message.toString();
    }
}

const customDispatch = function (topic, message, packet) {
    // console.log("App customdispatch -> " + topic)
    let sensors = this.state.data.sensors;
    if (sensors == undefined || sensors == []) {
        sensors = {}
    }
    let msg = parse(message);
    if (topic.endsWith("/output")) {
        msg.sensors.forEach(sensor => {
            if (sensors[sensor.sensor + "_output"] !== sensor)
                sensors[sensor.sensor + "_output"] = sensor;
        })
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
        // this.forceUpdate();
    } else if (topic.endsWith("/settings")) {
        let sensorId = topic.split("/")[6]
        sensors[sensorId + "_settings"] = {msg: msg, topic: topic};
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
    } else if (topic.endsWith("/mail/get")) {
        let sensorId = topic.split("/")[4]
        sensors[sensorId + "_email"] = {msg: msg, topic: topic};
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
    } else if (topic.endsWith("/lastPong")) {
        let sensorId = topic.split("/")[4]
        sensors[sensorId + "_lastPong"] = {msg: msg, topic: topic};
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
    } else if (topic.endsWith("/connection")) {
        let sensorId = topic.split("/")[4]
        sensors[sensorId + "_connection"] = {msg: msg, topic: topic};
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
    } else if (topic.endsWith("/history2")) {
        let sensorId = topic.split("/")[6]
        sensors[sensorId + "_history"] = msg;
        this.setState({data: {sensors: sensors}});
        store.dispatch(saveQueue(sensors))
    } else if (topic == "/regions") {
        let regions = {regions: msg};
        this.setState(regions);
        localStorage.setItem("regions", JSON.stringify(regions));
    }


}
const hist = createBrowserHistory();
let Router = subscribe({topic: '#', dispatch: customDispatch})(_Router);

const responseGoogle = (response) => {
    console.log(response);
    console.log(response)
}


class _App extends React.Component {
    saveCredentials() {
        console.log("before this")
        if (this) {
            console.log("after this")
            store.dispatch(userFetched(this.username, this.password))
            store.dispatch(userValid(undefined))
            // window.location.reload(false)
        }
    }

    componentDidMount = () => {
        console.log(JSON.stringify(navigator.userAgent));
        const channel = new BroadcastChannel('sw-messages-app-update');
        channel.addEventListener('message', event => {
            NotificationManager.success('Kliknij w celu aktualizacji!', 'Aktualizacja dostępna', 600000, () => {
                const bc = new BroadcastChannel("skipWaiting");
                bc.postMessage("skipWaiting");
            });
        });
    }

    render() {
        var username = new URLSearchParams(window.location.search).get("user")
        var password = new URLSearchParams(window.location.search).get("pass")
        if (username && password) {
            this.username = username;
            this.password = password;
            this.saveCredentials()
            hist.push("/")
            window.location.reload(false);
        }
        const {classes} = this.props;
        if (this.props.username != undefined && this.props.valid == true) {
            return (<Provider store={store}>
                <Connector
                    mqttProps={{
                        host: 'mqtt.aquilo.cloud',
                        clientId: "react" + Math.random(),
                        protocol: 'wss',
                        username:  this.props.username,
                        password: "aS*&k9PSn5Df",
                        port: 9002
                    }}>
                    <div>
                        <LoggedWatcher/>

                        <Router history={hist}>
                            <Switch>
                                <Route path="/admin" component={Admin}/>
                                <Route path="/rtl" component={RTL}/>
                                <Redirect from="/" to="/admin/list"/>
                            </Switch>
                        </Router></div>
                </Connector>
                <NotificationContainer/>

            </Provider>)
        } else if (this.props.username != undefined && this.props.valid == undefined) {
            return (
                <Provider store={store}>
                    <Connector
                        mqttProps={{
                            host: 'mqtt.aquilo.cloud',
                            clientId: "react" + Math.random(),
                            protocol: 'wss',
                            username:  this.props.username,
                            password: "aS*&k9PSn5Df",
                            port: 9002
                        }}>
                        <div>
                            <LoggedWatcher/>
                            {this.getLoginForm(classes)}
                        </div>
                    </Connector>
                </Provider>
            )
        } else {
            return this.getLoginForm(classes)
        }
    }

    getLoginForm(classes) {
        return (
            <GoogleOAuthProvider clientId="124158115573-3vke3sp90r1qkihgvhsjabl049vgqaab.apps.googleusercontent.com">
            <Provider store={store}>
                <GridContainer>
                    <GridItem xs={1} sm={2} md={3}></GridItem>
                    <GridItem xs={10} sm={8} md={6} style={{paddingTop: "5%"}}>
                        <Card chart
                              style={{
                                  width: "100%",
                                  alignItems: "center",
                                  maxWidth: "600px",
                                  margin: "15% auto"
                              }}>
                            <CardHeader style={{width: "50%", marginTop: "-30px"}}>
                                {/*<CardIcon color="primary" >*/}
                                {/*    <CardIcon color="primary">*/}
                                <img src={require("./icons/veolia_small.png")} alt="logo"
                                     style={{width: "100%", paddingTop: "100px"}}/>
                                {/*</CardIcon>*/}
                                {/*</CardIcon>*/}
                            </CardHeader>
                            <CardBody>
                                <br/>
                                <h3 className={classes.cardTitle} style={{textAlign: "center"}}>Dane
                                    logowania
                                    czujnika</h3>
                                <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        console.log("before this")
                                        if (this) {
                                            console.log("after this")
                                            const http = new XMLHttpRequest()

                                            http.open("GET", "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token="+credentialResponse.credential)
                                            http.send()

                                            http.onload = () => {
                                                console.log("http response")
                                                console.log(http.responseText)
                                                console.log(credentialResponse);
                                                store.dispatch(userFetched(JSON.parse(http.responseText).email, '124158115573-atn0rouka5sthu5hl1put2scva8pppmv.apps.googleusercontent.com'))
                                                store.dispatch(userValid(undefined))
                                            }
                                            // window.location.reload(false)
                                        }
                                    }}
                                    responseType="code"
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    Wersja: {preval`module.exports = new Date().toLocaleString();`}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={1} sm={2} md={3}></GridItem>
                </GridContainer>
                <NotificationContainer/>
            </Provider>
            </GoogleOAuthProvider>
        );
    }
}

const
    mapStateToProps = (state) => {
        return {
            username: state.user.username,
            password: state.user.password,
            valid: state.userValid.valid,
            queue: state.queue
        }
    };
const mapDispatchToProps = {userFetched: userFetched, saveQueue: saveQueue, userValid: userValid}; // (2)

const App = connect(mapStateToProps, mapDispatchToProps)(_App);

export default withStyles(styles)(App);
