import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
// import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-react/components/cardStyle.js";
import CardHeader from "../Card/CardHeader";
import DateRangeIcon from '@material-ui/icons/DateRange';
// import CardIcon from "../Card/CardIcon";
// import Icon from "@material-ui/core/Icon";
import CardFooter from "../Card/CardFooter";
// import Danger from "../Typography/Danger";
// import Warning from "@material-ui/icons/Warning";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import CardBody from "../Card/CardBody";
// import Circle from "../Circle/Circle";
import Dateformat from "dateformat"
import Chart from "../Chart/Chart";
import {withStyles} from "@material-ui/styles";
import DatePicker from "react-datepicker";
import Toggle from 'react-toggle'
import parseFromTimeZone from 'date-fns-timezone'



import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Hint from "react-portal-hint";
import {Dropdown} from "react-bootstrap";
import {subscribe} from "mqtt-react";
import _Sensors from "../Sensors/Sensors";
import Dialog from "@material-ui/core/Dialog";
import {store} from "../../store";
import {saveQueue} from "../../actions";
import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./styles.css"
import {useState} from "react";
import QrReader from 'react-qr-reader2'
import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'


// function timeout (ms) {
//     return new Promise(resolve => setTimeout(resolve, ms))
// }

Date.prototype.toJSON = function () {
    return moment(this).format();
}

Dateformat.i18n = {
    dayNames: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ],
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

// const [startScan, setStartScan] = React.useState(false);
// const [loadingScan, setLoadingScan] = useState(false);
// const [data, setData] = useState("");

var _this_ = null
class AddSensor extends React.Component {

    startScan = false;
    loadingScan = false;
    data = "";
    zoomCapabilities= {};
    hasZoomCapabilities = false;
    delay = 1000;
    constraints = {};
    streamTrack = {};


    constructor(props) {
        super(props);
        this.state = {};

        // this.handleScan = this.handleScan.bind(this)
        this.onChangeZoom = this.onChangeZoom.bind(this)
        // this.onLoadQRCodeScanner = this.onLoadQRCodeScanner.bind(this)
    }

    onChangeZoom(value){

        this.streamTrack.applyConstraints({
            advanced: [{ zoom: value/* ,focusMode: 'continuous',  focusDistance: _this_.state.constraints.advanced[0].focusDistance*/}]
        })
        _this_.setState({

            constraints: {
                advanced: [{ zoom: value }],
            }});

    }
    onLoadQRCodeScanner (args) {
        if (!args) {
            return
        }

        _this_.streamTrack = args.streamTrack
        const {streamTrack} = args;
        // https://bugs.chromium.org/p/chromium/issues/detail?id=711524
        setTimeout(()=>{
            const capabilities = streamTrack.getCapabilities()
            // alert(JSON.stringify(capabilities))
            if ('zoom' in capabilities) {
                const { min, max, step } = capabilities.zoom
                // alert(capabilities.zoom.max)
                //alert(max)
                _this_.setState({
                    streamTrack,
                    hasZoomCapabilities: true,
                    zoomCapabilities: {
                        min,
                        max,
                        step,
                    },
                    constraints: {
                        advanced: [{ zoom: min }],
                    },
                })
                // streamTrack.applyConstraints({
                //     advanced: [{focusMode: 'continuous', focusDistance: capabilities.focusDistance.min }]
                // })
            } else {
                _this_.setState( {hasZoomCapabilities: false})
            }
         },1000)

    }

    handleScan(scanData) {
        this.loadingScan = true;
        console.log(`loaded data data`, scanData);
        if (scanData && scanData !== "") {
            console.log(`loaded >>>`, scanData);
            this.data = scanData;
            this.startScan = false;
            this.loadingScan = false;
            // setPrecScan(scanData);
        }
    };

    handleError(err) {
        console.error(err);
    };

    componentDidMount() {
        const _this = this;
        _this_ = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            _this.setState({lat: position.coords.latitude, long: position.coords.longitude})
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
        });
        this.setState({
            result: '',
            delay: 1000,
            hasZoomCapabilities: false,
            zoomCapabilities: { min: 1, max: 1, step: 1 },
            constraints: {
                advanced: [{ zoom: this.state.min }],
            }
        });
    }

    getCard() {
        return <GridItem xs={12} sm={12} md={12} key={"gi_add"}>
            <Card>
                <CardHeader style={isMobile ? {padding: "0px"} : {}}>
                    <h3 style={isMobile ? {"textAlign": "center", margin: "0px"} : {"textAlign": "center"}}>
                    </h3>
                </CardHeader>
                <CardBody>
                    <div className="form-check form-check-inline" style={{"width": "100%"}}>
                        <CustomInput
                            labelText="Numer czujnika"
                            id={"sensor"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: false,
                                onChange: (value) => {
                                    this.setState({sensor: value.target.value})
                                },
                                value: this.state.sensor
                            }}
                        />
                        <Button color="primary" enabled="false"
                                onClick={() => {
                                    this.startScan = !this.startScan;
                                    this.setState({startScan: this.startScan});
                                }}>
                            {this.startScan ? "Zarzymaj" : "Skanuj"}
                        </Button>
                    </div>
                    <CustomInput
                        labelText="Kod weryfikacyjny"
                        id={"code"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            disabled: false,
                            onChange: (value) => {
                                this.setState({code: value.target.value})
                            },
                            value: this.state.code
                        }}
                    />

                    {this.state.startScan ? (
                        <>
                            <div>
                                <QrReader
                                    delay={this.state.delay}
                                    onError={this.handleError}
                                    onScan={(result, error) => {
                                        if (!!result) {
                                            if (JSON.parse(result).id != undefined) {
                                                this.setState({startScan: false});
                                                this.setState({
                                                    sensor: JSON.parse(result).id,
                                                    code: JSON.parse(result).code
                                                })
                                            }
                                        }
                                    }}
                                    onLoad={this.onLoadQRCodeScanner}
                                    style={{ width: '100%' }}
                                />
                                {this.state.hasZoomCapabilities == true ? (
                                    <div>
                                        <div>
                                            zoom {this.state.constraints.advanced[0].zoom}, min: {_this_.state.zoomCapabilities.min}, max:{' '}
                                            {this.state.zoomCapabilities.max}, step: {this.state.zoomCapabilities.step}
                                        </div>
                                        <Slider
                                            onChange={this.onChangeZoom}
                                            min={this.state.zoomCapabilities.min}
                                            max={this.state.zoomCapabilities.max}
                                            step={this.state.zoomCapabilities.step}
                                            value={this.state.constraints.advanced[0].zoom}
                                            trackStyle={{ backgroundColor: 'blue', height: 10 }}
                                            handleStyle={{
                                                borderColor: 'blue',
                                                height: 28,
                                                width: 28,
                                                marginLeft: -14,
                                                marginTop: -9,
                                                backgroundColor: 'black',
                                            }}
                                        />
                                    </div>
                                ):""}
                            </div>
                        </>
                    ) : ""}

                    <CustomInput
                        labelText="Adres"
                        id={"address"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            disabled: false,
                            onChange: (value) => {
                                this.setState({address: value.target.value})
                            },
                            value: this.state.address
                        }}
                    />
                    <div className="form-check form-check-inline" style={{"width": "100%"}}>
                        <CustomInput
                            labelText="Rejon"
                            id={"region"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            style={{"width": "100%", "float": "left"}}
                            inputProps={{
                                disabled: false,
                                editable: false,
                                value: this.state.region != undefined ? this.state.region.name : ""
                            }}

                        />
                        <div
                            style={{
                                border: "none",
                                background: "none",
                                outline: "none",
                                width: "45px",
                                "font-size": "25px",
                                "margin": "27px 0 0 0"
                            }}
                        >
                            <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    <i style={{
                                        border: "none",
                                        background: "none",
                                        color: "black",

                                        "font-size": "25px",
                                        "margin": "0 0 0 0"
                                    }}
                                       className="fa fa-edit"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        (localStorage.getItem("regions")!= undefined)?(JSON.parse(localStorage.getItem("regions")).regions.map((el) => {
                                            return <Dropdown.Item
                                                onClick={() => {
                                                    this.setState({region: el})
                                                }}
                                            >
                                                {el.name}
                                            </Dropdown.Item>
                                        })):null
                                    }


                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    {/*<CustomInput*/}

                    {/*    labelText="Współrzędne"*/}
                    {/*    id={"latLong"}*/}
                    {/*    formControlProps={{*/}
                    {/*        fullWidth: false*/}
                    {/*    }}*/}
                    {/*    inputProps={{*/}
                    {/*        disabled: false,*/}
                    {/*        onChange: (value) => console.log(value),*/}
                    {/*        value: this.state.lat + ", " + this.state.long*/}
                    {/*    }}*/}
                    {/*/>*/}

                </CardBody>
                <CardFooter  stats style={{"textAlign": "center"}}>
                    <Button disabled={!this.saveEnabled()} color="primary" enabled="false"
                            onClick={() => {
                                this.save();
                                this.waitForResponse();
                            }}>
                        Zapisz
                    </Button>
                </CardFooter>
            </Card>
        </GridItem>
    }

    saveEnabled() {
        return this.state.sensor != undefined && this.state.code != undefined && this.state.address != undefined && this.state.region != undefined &&
            this.state.sensor.length > 0 && this.state.code.length > 0 && this.state.address.length > 0;
    }

    save() {
        const {mqtt} = this.props;
        let topic = '/users/'+this.props.username+'/device/add';
        //console.log(topic);
        let msg = {
            number: this.state.sensor,
            code: this.state.code,
            address: this.state.address,
            lat: this.state.lat,
            long: this.state.long,
            region: this.state.region.id
        }
        // _this_ = this;
        mqtt.publish(topic, JSON.stringify(msg), {retain: false, qos: 2});

        ///users/pawel.rokoszny@gmail.com/device/add/response
    }

    waitForResponse() {

    }

    getToggleEnabled(sensorProps) {
        return ('true' == localStorage.getItem("batteryHistoryToggle_" + sensorProps.sensor));
    }

    isActive = (sensor, mode) => {
        if (this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
            if (msg.historySettings != undefined && msg.historySettings.periodUnit == mode) {
                return true
            }
        }
        return false;
    }

    isOpen(sensorProps) {
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorHistoryEdited;
    }

    handleClose = () => {
        this.setState({data: {sensorHistoryEdited: ''}});
    }


    handleSave = (sensor) => {
        this.setState({data: {sensorHistoryEdited: ''}});
        let topic = this.props.sensors[sensor.sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        const {mqtt} = this.props;
        //console.log(JSON.stringify(msg));
        //console.log(topic);
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});
    }



    handleScan (data) {
        if (data) {
            this.setState({ result: data })
        }
    }

    handleError (err) {
        console.error(err) // eslint-disable-line no-console
    }

    render() {

        return (
            this.getCard()
        )
    }

    changeHistoryRange(sensor, number, time) {
        let topic = this.props.sensors[sensor.sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        msg.historySettings.number = number;
        msg.historySettings.periodUnit = time;
        // console.log(JSON.stringify(msg))
        const {mqtt} = this.props;
        // console.log(JSON.stringify(msg));
        // console.log(topic);
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});
    }

    getStartDate = (sensor) => {
        if (this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
            if (msg != undefined && msg.historySettings != undefined && msg.historySettings.date != undefined) {
                if (msg.historySettings.date instanceof Date) {
                    return msg.historySettings.date;
                } else {
                    console.log("Dateee=" + msg.historySettings.date);
                    const parse1 = moment(msg.historySettings.date, "yyyy-MM-dd'T'HH:mm:ssZ").toDate();
                    return parse1;
                }
            }
        }
        return Date.now();
    }

    handleOpen = (sensorProps) => {
        this.setState({data: {sensorHistoryEdited: sensorProps.sensor}});
        this.setState({date: this.getStartDate(sensorProps.sensor)});
    }

    setStartDate = (date, sensor) => {
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        this.setState({date: date});
        msg.historySettings.date = date;
        msg.historySettings.number = 1;
        msg.historySettings.periodUnit = "DATE";
        this.setState({data: {sensorHistoryEdited: sensor.sensor}});
    }


    handleBatteryHistoryChange = (sensorProps) => {
        console.log(sensorProps.sensor)
        let batteryToggleState = 'true' == localStorage.getItem("batteryHistoryToggle_" + sensorProps.sensor);
        batteryToggleState = !batteryToggleState;
        localStorage.setItem("batteryHistoryToggle_" + sensorProps.sensor, batteryToggleState);
        // console.log(JSON.stringify(sensorProps))
        let data = {};
        data["batteryHistoryToggle_" + sensorProps.sensor] = batteryToggleState;
        this.setState({data: data});
    }

    getBatteryVisible = (sensorProps) => {

        return this.state.data != undefined && this.state.data["batteryHistoryToggle_" + sensorProps.sensor] == 'true';
    }
}

const customDispatch = function (topic, message, packet) {
    if(topic.endsWith("/device/add/response")) {
        let response = JSON.parse((String(message)));
        if (response.correct == false) {
            alert('błąd')
            _this_.setState({sensor: "", code: "", address: "", lat: "", long: "", region: undefined})
        } else {
            alert("ok")
            this.setState({sensor: "", code: "", address: "", lat: "", long: "", region: undefined})
        }
    }
}

export default withStyles(styles)(subscribe({topic: '/users/+/device/add/response', dispatch: customDispatch})(AddSensor));

