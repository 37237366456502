import React from "react";
// react plugin for creating charts
// @material-ui/core
// @material-ui/icons
// core components
// import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";

import _SensorsTable from "components/SensorsTable/SensorsTable.js";
import {subscribe} from 'mqtt-react';
import {connect} from 'react-redux'
// import Button from "components/CustomButtons/Button.js";
// import { contactsFetched } from "./actions";
import {withStyles} from '@material-ui/styles';
// import preval from 'preval.macro'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// import {store} from "../../store";
import {saveQueue, userValid, userFetched} from "../../actions";
import {Router as _Router} from "react-router";
import Card from "../../components/Card/Card";
import GridItem from "../../components/Grid/GridItem";
import CardHeader from "../../components/Card/CardHeader";
import {isMobile} from "react-device-detect";
import Hint from "react-portal-hint";
import FlatCardBody from "../../components/Card/FlatCardBody";
// import TableList from "../TableList/TableList";
// import Table from "../../components/Table/Table";
import CardBody from "../../components/Card/CardBody";

// import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import CustomInput from "../../components/CustomInput/CustomInput";
// import { loadState, saveState } from '../lib/localStorage'
// import {Button} from "@material-ui/core";

// const
//
//     useStyles = makeStyles(styles);


const parse = function (message) {
    try {
        var item = JSON.parse(message);
        return item;
    } catch (e) {
        return message.toString();
    }
}

// const _toConsumableArray = function (arr) {
//     if (Array.isArray(arr)) {
//         for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
//             arr2[i] = arr[i];
//         }
//         return arr2;
//     } else {
//         return Array.from(arr);
//     }
// }

let SensorsTable = subscribe({topic: '__'})(_SensorsTable);
//let Sensors = subscribe({topic: '/users/'+this.props.username+'/definition'})(_Sensors);
// const useStyles = makeStyles(styles);
// const classes = useStyles();


// const store = createStore(combineReducers);

class _SensorsList extends React.Component {

    constructor(props, context) {

        super(props, context);
        this.state = {sensors: {}};

    }

//
    username;
    password;
    full_name;
    queue;

    // saveCredentials() {
    //     store.dispatch(userFetched(this.username, this.password))
    //     // this.state.username = this.username;
    //     // this.props.password = this.password;
    //
    // }

    render() {
        // const {classes} = this.props;
        if (this.props.username !== undefined) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className="cardTitleWhite">Aktualny stan systemu</h4>

                            </CardHeader>
                            <CardBody>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Lokalizacja</TableCell>
                                                <TableCell>Rejon</TableCell>
                                                <TableCell>Stan</TableCell>
                                                <TableCell>Odczyt [cm]</TableCell>
                                                <TableCell>Poziom [cm]</TableCell>
                                                <TableCell>Wilgotność [%]</TableCell>
                                                <TableCell>Temperatura [°C]</TableCell>
                                                <TableCell>Ostatni odczyt</TableCell>
                                                <TableCell>Bateria [V]</TableCell>
                                                <TableCell>Operator</TableCell>
                                                <TableCell>Sygnał [dB]</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries((this.props.queue.queue)!=undefined?this.props.queue.queue:[]).filter(function (t, m) {
                                                return t[0].endsWith("output")
                                            }).sort((a, b) => {
                                                console.log(a);
                                                console.log(b);
                                                console.log(a[1].red)
                                                if (a[1].red == true){
                                                    if (b[1].red == true){
                                                        return 0
                                                    }
                                                    return -1
                                                }
                                                if (b[1].red == true){
                                                    return 1
                                                }
                                                if (a[1].yellow == true){
                                                    if (b[1].yellow == true){
                                                        return 0
                                                    }
                                                    return -1
                                                }
                                                if (b[1].yellow == true){
                                                    return 11
                                                }
                                                return 0;
                                            }).map((row) => (
                                                <TableRow key={row[1].sensor}>
                                                    <TableCell>{row[1].address + " (" + row[1].sensor + ")"}</TableCell>
                                                    <TableCell>{row[1].region}</TableCell>
                                                    <TableCell>
                                                        <div
                                                            style={this.getRowStyle(row[1])}>{this.getStatusLabel(row[1])}</div>
                                                    </TableCell>
                                                    <TableCell>{row[1].lastRead / 100}</TableCell>
                                                    <TableCell>{(((row[1].maxLevel!=null)?(row[1].maxLevel):row[1].lastRead) - row[1].lastRead) / 100}</TableCell>
                                                    <TableCell>{row[1].humidity}</TableCell>
                                                    <TableCell>{row[1].temperature}</TableCell>
                                                    <TableCell>{new Date(row[1].lastReadTime2).toLocaleString()}</TableCell>
                                                    <TableCell>{row[1].battery / 1000}</TableCell>
                                                    <TableCell>{row[1].operator + (row[1].connectionType!= undefined?(" ("+ row[1].connectionType +")"):"")}</TableCell>
                                                    <TableCell>{Math.abs(row[1].rssi)}</TableCell>



                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/*<Table*/}
                                {/*    tableHeaderColor="primary"*/}
                                {/*    tableHead={[, "]", "", "", "", "", "", ""]}*/}
                                {/*    tableData={this.getPreparedData(this.props.queue.queue)}*/}
                                {/*/>*/}
                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            )
        }
    }

    getRowStyle(row) {
        let v = {
            background: "blue",
            padding: "5px",
            textAlign: "center",
            "border-radius": "11px",
            color: "white",
            "font-weight": "900"
        };
        if (row.red == true) {
            v.background = "red";
        } else if (row.yellow == true) {
            v.background = "yellow";
            v.color = "black;"
        }
        return v;
    }

    getStatusLabel(row) {
        if (row.red == true) {
            return "AWARIA";
        } else if (row.yellow == true) {
            return "PODEJRZENIE AWARII";
        }
        return "OK"
    }

    getPreparedData(props) {
        return Object.entries(props).filter(function (t, m) {
            return t[0].endsWith("output")
        }).map((t, message) => {
            console.log(t);
            console.log(message);
            return [t[1].address + " (" + t[1].sensor + ")", t[1].lastRead / 100, t[1].humidity, t[1].temperature, new Date(t[1].lastReadTime2).toLocaleString(), t[1].battery / 1000, t[1].operator, Math.abs(t[1].rssi)]
        })

    }
}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        valid: state.userValid.valid,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, saveQueue: saveQueue, userValid: userValid}; // (2)

const SensorsList = connect(mapStateToProps, mapDispatchToProps)(_SensorsList); // (3)

/*
* const __Dashboard = connect(mapStateToProps, mapDispatchToProps)(_SensorsList); // (3)
const SensorsList = subscribe({topic: "/users/"+JSON.parse(localStorage.getItem("state")).user.username+"/definition", dispatch: customDispatch})(__Dashboard);*/
export default withStyles(styles)(SensorsList);


