import React from "react";
// react plugin for creating charts
// @material-ui/core
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
// import {subscribe} from 'mqtt-react';
import {connect} from 'react-redux'
// import { saveQueue } from "./actions";
// import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Redirect } from 'react-router-dom';
import {saveQueue, userFetched, userValid} from "../../actions";
import SensorHistory from "../../components/SensorHistory/SensorHistory";
import AddSensor from "../../components/AddSensor/AddSensor";
import {store} from "../../store";


const parse = function (message) {
    try {
        var item = JSON.parse(message);
        return item;
    } catch (e) {
        return message.toString();
    }
}

class Logout extends React.Component {

    constructor(props, context) {
        super(props, context);
        store.dispatch(saveQueue({}));
        store.dispatch(userFetched(null, null))
        store.dispatch(userValid(true))
    }

//
    username;
    password;
    full_name;

    render() {
        return (<Redirect to='/' />)
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, saveQueue: saveQueue}; // (2)

// const AddSensors = connect(mapStateToProps, mapDispatchToProps)(_AddSensor); // (3)

export default Logout;


