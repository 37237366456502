import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
// import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import {withStyles} from "@material-ui/styles";
import Carousel from 'react-bootstrap/Carousel'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
// core components
import styles from "assets/jss/material-dashboard-react/components/cardStyle.js";
import CardHeader from "../Card/CardHeader";
// import CardIcon from "../Card/CardIcon";
// import Icon from "@material-ui/core/Icon";
import CardFooter from "../Card/CardFooter";
// import Danger from "../Typography/Danger";
// import Warning from "@material-ui/icons/Warning";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import FlatCardBody from "../Card/FlatCardBody";
import Circle from "../Circle/Circle";
import Dateformat from "dateformat"
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
// import {CarouselItem} from "react-bootstrap";
import CustomInput from "../CustomInput/CustomInput";
import Hint from "react-portal-hint";
import "react-portal-hint/dist/default.css";
import "../../assets/css/default.css"
import {store} from "../../store";
import {saveQueue, userValid, userFetched} from "../../actions";
import {connect} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DatePicker from "react-datepicker";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import {Dropdown} from "react-bootstrap";
import DateRangeIcon from "@material-ui/icons/DateRange";

// import {Connector, publish} from 'mqtt-react';

// const useStyles = makeStyles(styles);


Dateformat.i18n = {
    dayNames: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ],
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

function getStatusText(props, sensorId) {
    var ret = ""
    var lastRead = new Date().getTime() - Date.parse((props.sensors[sensorId + "_output"].lastSensorMessageTime != undefined) ? props.sensors[sensorId + "_output"].lastSensorMessageTime : props.sensors[sensorId + "_output"].lastReadTime2);
    if (lastRead <= 1000 * 60 * 60 * 24) {
        ret += "Status nadajnika: Połączony\n";
    } else {
        ret += "Status nadajnika: Brak połączenia\n";
    }
    if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"] && !props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var diff = new Date().getTime() - Date.parse(props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"].msg);
        if (diff <= 1000 * 60 * 30) {
            ret += "Status odbiornika: Połączony"
        } else {
            ret = "Status nadajnika: Brak połączenia\nStatus odbiornika: Brak połączenia"
        }
    } else if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var online = props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"].msg == "online";
        if (online) {
            ret += "Status odbiornika: Połączony"
        } else {
            ret = "Status nadajnika: Brak połączenia\nStatus odbiornika: Brak połączenia";
        }
    }
    return ret;
}

function isStatusChecked(props, sensorId) {
    var ret = ""
    var lastRead = new Date().getTime() - Date.parse(props.sensors[sensorId + "_output"].lastSensorMessageTime);
    if (lastRead > 1000 * 60 * 60 * 24) {
        return false;
    }
    if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"] && !props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var diff = new Date().getTime() - Date.parse(props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"].msg);
        if (diff > 1000 * 60 * 30) {
            return false;
        }
    } else if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        return props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"].msg == "online";
    }
    return true;
}

class _SensorsTable extends React.Component/*(props)*/ {
    constructor(props, context) {
        super(props, context);
        // this.textInput = React.createRef();
        this.state = {
            index: [],
            direction: [],
            carouselItemCount: 2
        }
    }

    // const classes = useStyles();
    // let circle;

    getCircle(topic) {
        if (topic.endsWith("/output")) {
            return <Circle/>
        }
        return undefined;
    }

    isActive(sensorId, use) {
        if (this.props.sensors[sensorId + '_settings'] != undefined) {
            let settings = this.props.sensors[sensorId + '_settings'].msg;
            if (use == 'sewage' && (settings == undefined || settings.tankUse == undefined || settings.tankUse.use == undefined || settings.tankUse.use == 'sewage')) {
                return true;
            } else if (settings != undefined && settings.tankUse != undefined && settings.tankUse.use != undefined && settings.tankUse.use == 'rainwater' && use == 'rainwater') {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getTankUsage(sensorId) {
        if (this.props.sensors[sensorId + '_settings'] != undefined) {
            let settings = this.props.sensors[sensorId + '_settings'].msg;
            if ((settings == undefined || settings.tankUse == undefined || settings.tankUse.use == undefined || settings.tankUse.use == 'sewage')) {
                return "szambo";
            } else if (settings != undefined && settings.tankUse != undefined && settings.tankUse.use != undefined && settings.tankUse.use == 'rainwater') {
                return "deszczówka"
            }
        } else return "szambo"
    }

    getPercentage(sensor) {
        if (sensor.tankLevelPercent != undefined && !isNaN(sensor.tankLevelPercent)) {
            return sensor.tankLevelPercent;
        }
        // console.log(JSON.stringify(sensor));
        const number = ((sensor.maxLevel - sensor.lastRead) / (sensor.maxLevel - sensor.fullLevel * 100)) * 100;
        if (Number.isNaN(number)) {
            return 0;
        }
        return Math.round(Math.min(100, Math.max(0, number)));
    }

    getLastRead(sensor) {
        return Dateformat(Date.parse(sensor.lastReadTime2), "dd mmmm yyyy 'godz: ' HH:MM");
    }

    getLevel(sensor) {
        return Math.round(sensor.lastRead / 100);
    }

    isOpen(sensorProps) {
        // console.log(this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorEmailEdited);
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorEmailEdited;
    }

    getSensorEmail = (sensor) => {
        if (this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_email"].msg;
            if (msg != undefined && msg.email != undefined && msg.historySettings.date != undefined) {
                if (msg.historySettings.date instanceof Date) {
                    return msg.historySettings.date;
                } else {
                    console.log("Dateee=" + msg.historySettings.date);
                    const parse1 = moment(msg.historySettings.date, "yyyy-MM-dd'T'HH:mm:ssZ").toDate();
                    return parse1;
                }
            }
        }
        return Date.now();
    }

    handleClose = () => {

        this.setState({
            data: {
                sensorEmailEdited: null,
                emailToEdit: null
            }
        });
    }

    getDaysToFull(sensor) {
        const sensor1 = this.props.sensors[sensor.sensor + "_settings"];
        if (sensor1 != null && sensor1.msg != null && sensor1.msg.tankUse != null && sensor1.msg.tankUse.use == 'rainwater') {
            return "";
        }
        if (sensor.nextEmpty !== undefined && sensor.nextEmpty !== "") {

            let timeDifference = Math.abs(Date.now() - Date.parse(sensor.nextEmpty));
            let differentDays = Math.floor(timeDifference / (1000 * 3600 * 24));
            if (differentDays === 0) {
                return (<p style={{color: "red"}}>Dzisiaj powinieneś opróżnić zbiornik</p>)
            }
            if (differentDays === 1) {
                return (<p style={{color: "red"}}>Opróżnij zbiornik najpóźniej za 1 dzień.</p>);
            } else if (!Number.isNaN(differentDays)) {
                return (<p>Opróżnij zbiornik najpóźniej za {differentDays} dni.</p>)
            } else {
                return ""
            }
        }
    }

    save = (sensor) => {
        let topic = this.props.sensors[sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor + "_settings"].msg;

        // console.log(JSON.stringify(msg))
        const {mqtt} = this.props;
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});

    }

    toggleCarousel = (sensorDirection, sensor) => {
        let index = this.state.index;
        if (index[sensor] === undefined) {
            index[sensor] = 0;
        }
        let direction = this.state.direction;
        direction[sensor] = sensorDirection;

        const [min, max] = [0, this.state.carouselItemCount - 1]

        if (sensorDirection === 'next') {
            index[sensor]++

        } else if (sensorDirection === 'prev') {
            index[sensor]--
        }

        if (index[sensor] > max) {
            // at max, start from top
            index[sensor] = 0
        }

        if (index[sensor] < min) {
            // at min, start from max
            index[sensor] = max
        }
        this.setState({
            direction,
            index
        })
    }

    depthChanged(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        let maxLevelAuto = this.props.sensors[value.target.id.replace(paramName + "_", "") + "_output"].maxLevel;
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.mode = value.target.value;
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        console.log(value.target.value);
        document.getElementById(value.target.id).setAttribute("checked", "checked");
        return value.target.value;
    }

    autoSelected(sensorId, sensor1) {
        return this.props.sensors[sensorId + "_settings"] == undefined || this.props.sensors[sensorId + "_settings"].msg.tankDepth == undefined || this.props.sensors[sensorId + "_settings"].msg.tankDepth.mode != "manual";
    }

    getDepthValue(sensorId) {
        if (this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.tankDepth != undefined && this.props.sensors[sensorId + "_settings"].msg.tankDepth.mode == "manual") {
            if (this.props.sensors[sensorId + "_settings"].msg.tankDepth.manualValue != undefined) {
                return this.props.sensors[sensorId + "_settings"].msg.tankDepth.manualValue;
            } else {
                if (this.props.sensors[sensorId + "_output"].maxLevel != undefined) {
                    return Math.round(this.props.sensors[sensorId + "_output"].maxLevel / 100);
                } else {
                    return "200";
                }
            }
        }
        return "";
    }

    getMailVerifiedValue(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != "") {
            return this.props.sensors[sensorId + "_email"].msg.active ? "none" : "";
        }
        return "none";
    }

    isTankShapeDialogOpen(sensorProps) {
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.tankShapeEdited;
    }

    handleTankShapeDialogClose() {

    }

    getTankShape(settings) {
        if (settings != undefined && settings.msg.shape != undefined) {
            return settings.msg.shape.shape;
        } else {
            return "box";
        }
    }

    getNotificationMailValue(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined) {
            return this.props.sensors[sensorId + "_email"].msg.emailAddress;
        }
        return "";
    }

    getTankShapeValue(sensorId) {
        if (this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.shape != undefined) {
            const shape = this.props.sensors[sensorId + "_settings"].msg.shape.shape;
            if ("circle" == shape) {
                return "kula";
            } else if ("box" == shape) {
                return "kostka";
            }
        }
        return "kostka";
    }

    getNotificationMailValueToEdit(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined) {
            return this.props.sensors[sensorId + "_email"].msg.emailAddress;
        }
        return "";
    }

    showMailNotificationEditScreen(sensorId) {
        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined ? this.props.sensors[sensorId + "_email"].msg.emailAddress : ""
            }
        });
    }

    showShapeEditScreen(sensorId) {
        this.setState({
            data: {
                tankShapeEdited: sensorId,
                shapeToEdit: this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.shape != undefined ? this.props.sensors[sensorId + "_settings"].msg.shape.shape : ""
            }
        });
    }

    depthValueChanging(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        let manualValue = value.target.value;
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.manualValue = manualValue
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        value.target.value = manualValue;
        return manualValue;
    }

    shapeValueChanging(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (paramName == "") {
            return;
        }

        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["shape"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["shape"] = {};
        }
        let manualValue = value.target.value;
        console.log(manualValue);
        console.log(value.target.id.replace(paramName + "_", "") + "_settings");
        // this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.shape.shape = manualValue
        // this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        // value.target.value = manualValue;
        this.handleClose();
        return manualValue;
    }

    tankUseValueChanging(sensorId, settings, val) {
        if (this.props.sensors[sensorId + "_settings"].msg["tankUse"] == undefined) {
            this.props.sensors[sensorId + "_settings"].msg["tankUse"] = {};
        }
        console.log(sensorId + "_settings");
        this.props.sensors[sensorId + "_settings"].msg.tankUse.use = val
        this.setState({[sensorId + "_settings"]: this.props.sensors[sensorId + "_settings"].msg});

    }

    depthValueChanged(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        let manualValue = Math.min(450, Math.max(30, value.target.value));
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.manualValue = manualValue
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        value.target.value = manualValue;
        return manualValue;
    }

    handleSaveEmail(sensor) {
        const {mqtt} = this.props;
        let topic = this.props.sensors[sensor.sensor + "_email"].topic.replace("/get", "/add");
        mqtt.publish(topic, JSON.stringify({
            "emailAddress": this.state.data.emailToEdit,
            "active": false
        }), {retain: false, qos: 2});
        this.setState({data: {sensorHistoryEdited: ''}});
    }

    handleNameChange = (value) => {
        let paramName = value.target.id.split("_")[0];
        if (typeof this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] == "number" && value.target.value != '') {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] = Number(value.target.value);
        } else {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] = value.target.value;
        }
        // console.log(paramName);
        // console.log(JSON.stringify(this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName]))
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        return value.target.value;
    }

    handleEmailForNotificationChange = (value, sensorId) => {
        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: value.target.value
            }
        });
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: value.target.value,
                emailValid: re.test(String(value.target.value).toLowerCase()) || value.target.value.length == 0
            }
        });
        return value.target.value;
    }

    handleTankShapeChange = (value, sensorId) => {
        this.setState({
            data: {
                tankShapeEdited: sensorId,
                shapeToEdit: value.target.value
            }
        });

        return value.target.value;
    }


    getCard(sensorId, sensor, settings) {
        return <tr><td>{sensor.name}</td>
        </tr>

    }

    saveEnabled(sensorId) {
        return this.props.sensors[sensorId + '_settings'] != undefined
            && this.props.sensors[sensorId + '_settings'].msg != undefined
            && this.props.sensors[sensorId + '_settings'].msg.maxLevel != ''
            && this.props.sensors[sensorId + '_settings'].msg.maxLiquidLevelToNotify != ''
            && this.props.sensors[sensorId + '_settings'].msg.maxDaysBeforeToNotify != '';
    }

    render() {
        // return ""
        return Object.entries(this.props.sensors).filter(function (t, m) {
            return t[0].endsWith("output")
        }).map((t, message) => this.getCard(t[0].replace("_output", ""), t[1], this.props.sensors[t[0].replace("_output", "_settings")]))
    }


}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        valid: state.userValid.valid,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, userValid: userValid}; // (2)

const SensorsTable = connect(mapStateToProps, mapDispatchToProps)(_SensorsTable); // (3)

export default withStyles(styles)(SensorsTable);

